import { useEffect } from "react";
import UpcomingShows from "./UpcomingShows";
import BlogCard from "./BlogCard";
import about from "../media/images/about-850.webp";

export default function Home({ blogPosts, shows, setShows }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function mapPosts() {
    return blogPosts.map((post, i) => {
      return (
        <BlogCard
          key={i}
          post={post}
          index={Math.abs(i - (blogPosts.length - 1))}
        />
      );
    });
  }
  return (
    <main>
      <div className="about">
        <img
          src={about}
          className="about-pic"
          alt=""
          width="850"
          height="842"
        />
        <p className="about-text">
        Matt Jordan grew up in Chicago. When he wasn't dodging bullets, he was being bullied for being fat, and contemplating his latent homosexuality while his drunk parents threw dishes at each other. A comedian was in the making.

Yes, Matt is gay and doesn’t really care whether you like gay people or not. Sometimes he’s not sure he does. He may be the only gay guy who was annoyed when gay marriage was legalized.

Matt has had the honor of opening for Jim Norton, and also several other headliners whose names he forgot because he sucks at self-promotion. He runs Capitol Comedy Hour, a monthly variety show, and the Local Madison Comedy website.

Matt and his two cats live in Madison, Wis., where he takes full advantage of being an oppressed minority.

        </p>
      </div>
      <div className="columns">
        <section className="recent-blog">
          <h2>Recent Blog Posts</h2>
          <div className="blogs">{mapPosts()}</div>
        </section>
        <div className="divider"></div>
        <UpcomingShows shows={shows} setShows={setShows} />
      </div>
    </main>
  );
}
